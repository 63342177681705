import { Injectable } from "@angular/core";
import { Subject, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Lien, ApiResponse } from "../../models";
import { ApiService, EnvService } from "../../services";

@Injectable()
export class Liens {
  lien: Lien[] = [];
  private queryString: string;
  id: any;

  constructor(private apiService: ApiService, private env: EnvService) {
    this.queryString = `?sort=-createdAt`;
    this.queryString += `&populate=staff,customer,account,createdBy,updatedBy`;
    this.recordRetrieve(this.queryString).then((res) => {
      this.lien = res.payload;
      console.log(res);
    });
  }

  query(params?: any) {
    if (!params) {
      return this.lien;
    }
    return this.lien.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: Lien) {
    this.lien.push(record);
  }

  delete(record: Lien) {
    this.lien.splice(this.lien.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ""): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/liens${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Lien): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/liens`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log("recordCreate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=staff,customer,account,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordUpdate(record: Lien, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/liens/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordUpdate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=staff,customer, account,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordPatch(record: Lien): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/liens/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordOperation(record: Lien, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/liens/operation/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordDelete(record: Lien): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/liens/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }
}
