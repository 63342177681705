import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from "rxjs"

import { AuthService, InternetService } from '../../services';
import { ROUTES } from '../sidebar/sidebar.component';
import { safeGet, nextDate, compareObjects, getFullname, getLocalStorage } from '../../helpers';
import { Deposit, Staff } from '../../models';
import { Deposits } from '../../providers'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {

    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private $layer: any;
    public isCollapsed = true;
    public canRecordSales = true;
    // items:Array<Deposit> = [];
    notificationItems:Array<any> = [];
    notifications: number = 0;
    currentRecords;
    getFullname = getFullname;
    


    internet$ = new BehaviorSubject<boolean>(false);
    user: any = getLocalStorage('user');
    
    

    constructor(
      location: Location,
      private element: ElementRef,
      private router: Router,
      private authService: AuthService,
      private internetService: InternetService,
      private deposits: Deposits
    ) {
      console.log(this.internet$)
        this.user = this.authService.getUser();
        this.location = location;
        this.sidebarVisible = false;
        this.internet$ = this.internetService.connected$;
        this.deposits.recordRetrieve(`?sort=-createdAt&limit=1`).then(res => {
          if(res.success){
            this.notificationItems = res.payload;
          }
        })

    }

    logout() {
      this.authService.userLogOut();
    }

    monitor(): Observable<boolean> {
      return new Observable<boolean>(subscriber => {
        setInterval(() => {
          this.internet$ = this.internetService.connected$;
        }, 10000);
      });
    }

    


    ngOnInit() {
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.router.events.subscribe((event) => {
        this.sidebarClose();
         this.$layer = document.getElementsByClassName('close-layer')[0];
         if (this.$layer) {
            this.$layer.remove();
           this.mobile_menu_visible = 0;
         }
     });
     this.monitorTimeForSales();
     this.monitorNotifications();
    }

    collapse() {
      this.isCollapsed = !this.isCollapsed;
      const navbar = document.getElementsByTagName('nav')[0];
      if (!this.isCollapsed) {
        navbar.classList.remove('navbar-transparent');
        navbar.classList.add('bg-white');
      } else {
        navbar.classList.add('navbar-transparent');
        navbar.classList.remove('bg-white');
      }

    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
        const html = document.getElementsByTagName('html')[0];
        if (window.innerWidth < 991) {
          mainPanel.style.position = 'fixed';
        }

        setTimeout(function() {
            toggleButton.classList.add('toggled');
        }, 500);

        html.classList.add('nav-open');

        this.sidebarVisible = true;
    }

    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        let mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];

        if (window.innerWidth < 991) {
          setTimeout(function() {
            //console.log(mainPanel);
            mainPanel.style.position = '';
          }, 500);
        }
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    }

    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const html = document.getElementsByTagName('html')[0];
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const html = document.getElementsByTagName('html')[0];

        if (this.mobile_menu_visible === 1) {
            // $('html').removeClass('nav-open');
            html.classList.remove('nav-open');
            if (this.$layer) {
                this.$layer.remove();
            }
            setTimeout(function() {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function() {
                $toggle.classList.add('toggled');
            }, 430);

            const $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (html.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (html.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function() {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function() { // asign a function
              html.classList.remove('nav-open');
              this.mobile_menu_visible = 0;
              $layer.classList.remove('visible');
              setTimeout(function() {
                  $layer.remove();
                  $toggle.classList.remove('toggled');
              }, 400);
            }.bind(this);

            html.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    }

    getTitle(): string {
      try {
        const subsidiary = safeGet(this.user,'subsidiary');
        // const terminal = safeGet(this.user.terminal,'name');
        const terminal = this.user.terminals;
        let titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice( 2 );
        }
        titlee = titlee.split('/').pop();

        for (let item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return `${subsidiary} ${terminal} - ${this.listTitles[item].title}`;
            }
        }
        return `${getFullname(this.user)} -eWallet Dashboard`;
      } catch(err){
      console.log(err.log)
    }
  }

  monitorNotifications(){
    setInterval(()=>{
      this.getNotifications();
      console.log('monitoring notifications');
    }, 60000);
  }


  monitorTimeForSales(){
    setInterval(()=>{
      let now = new Date();
      let tomorrow = nextDate();
      if(now !== tomorrow){
        this.canRecordSales = true;
        this.setLocalStorage('canRecord', this.canRecordSales)
        return this.canRecordSales;
      }else{
        return this.canRecordSales = false;
      }
    }, 1000);
    
  }

  setLocalStorage(name: string, data: any){
    if(localStorage.getItem(name) === null || undefined){
      window.localStorage.setItem(name, JSON.stringify(data));
    }else{
      if(typeof data == "string" || 'number'){
        window.localStorage.setItem(name, data);
      }else{
        let previous = JSON.parse(window.localStorage.getItem(name));
        previous.push(data);
        window.localStorage.setItem(name, previous);
      }
    }
  }
  private incrementNotification(amount: number = 1){
    this.notifications += amount;
  }

  getNotifications(){
    if(this.notificationItems.length < 1){
      this.getParcels();
      this.notificationItems.push(this.currentRecords[0]);
    }
    let previousParcel = this.notificationItems[0];
    console.log('previousParcel', previousParcel);
    
    let query = `?sort=-createAt&limit=1`;
    this.deposits.recordRetrieve(query).then(res=>{
      if(res.success){
        console.log('res.payload', previousParcel);
        let isSame = compareObjects(previousParcel, res.payload[0]);
        console.log(isSame);
        if(!isSame){
          this.notificationItems.push(res.payload[0]);
          console.log(this.notificationItems);
          this.incrementNotification();
        }else{
          this.notifications --;
        }
      }
    }).catch((err)=>{
      console.log('notifications not working');
    })
  }

  getParcels(){
    this.deposits.recordRetrieve(`?sort=-createdAt&limit=1`).then(res=>{
      this.currentRecords = res.payload;
    })
  }
}
