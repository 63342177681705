import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  LocationStrategy,
  HashLocationStrategy,
  DatePipe,
} from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
//import { FormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { DataTablesModule } from "angular-datatables";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { NgxLoadingModule } from "ngx-loading";

// import { FlutterwaveModule } from "flutterwave-angular-v3";
// import { Angular4PaystackModule } from 'angular4-paystack';

import { LoginComponent } from "./pages/login/login.component";
import { ConnectionServiceModule } from "ng-connection-service";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { LoginOtpComponent } from "./pages/login-otp/login-otp.component";

import {
  AuthService,
  ApiService,
  AuthGuard,
  EnvService,
  JwtInterceptor,
  ErrorInterceptor,
  InternetService,
  TimeoutInterceptor,
  DEFAULT_TIMEOUT,
} from "./services";

import {
  Accounts,
  //BankRegisters,
  //Coupons,
  Customers,
  Deposits,
  EnterprisePayments,
  Settings,
  Transfers,
  Liens,
  //Royalties,
  Withdraws,
  Transactions,
  Staffs,
  Banks,
  AutoTransfers,
  Enterprises,
  Terminals,
  Merchants
} from "./providers";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgxLoadingModule.forRoot({}),
    RouterModule,
    AppRoutingModule,
    //FlutterwaveModule,
    //Angular4PaystackModule.forRoot,
    NgbModule,
    ConnectionServiceModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    DataTablesModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    LoginOtpComponent,
  ],
  providers: [
    AuthService,
    ApiService,
    AuthGuard,
    EnvService,
    InternetService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }],
    DatePipe,
    Accounts,
    //BankRegisters,
    //Coupons,
    Customers,
    Deposits,
    Settings,
    Transfers,
    Enterprises,
    EnterprisePayments,
    Liens,
    //Royalties,
    Banks,
    Withdraws,
    Transactions,
    Terminals,
    Staffs,
    AutoTransfers,
    Merchants
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
