import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { BankRegister, ApiResponse } from "../../models";
import { ApiService, EnvService } from "../../services";

@Injectable()
export class BankRegisters {
  bankRegisters: BankRegister[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=items,transaction, deposit,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then((res) => {
      this.bankRegisters = res.payload;
      console.log(res);
    });
  }

  query(params?: any) {
    if (!params) {
      return this.bankRegisters;
    }
    return this.bankRegisters.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: BankRegister) {
    this.bankRegisters.push(record);
  }

  delete(record: BankRegister) {
    this.bankRegisters.splice(this.bankRegisters.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ""): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/bank-registers${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: BankRegister): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/bank-registers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log("recordCreate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=items,deposit,transaction,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordUpdate(record: BankRegister, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/bank-registers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordUpdate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=items,deposit,transaction,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordPatch(record: BankRegister): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/bank-registers/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordDelete(record: BankRegister): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/bank-registers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }
}
