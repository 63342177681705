import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  sub: any[];
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "business_chart-bar-32",
    class: "",
    sub: null,
  },
  // {
  //   path: "/customer",
  //   title: "Customer",
  //   icon: "users_single-02",
  //   class: "",
  //   sub: null,
  // },
  {
    path: "/account",
    title: "Account",
    icon: "shopping_credit-card",
    class: "",
    sub: null,
  },
  {
    path: "/bank",
    title: "Bank",
    icon: "shopping_credit-card",
    class: "",
    sub: null,
  },
  {
    path: "/deposit",
    title: "Deposit",
    icon: "business_money-coins",
    class: "",
    sub: null,
  },
  {
    path: "/transfer",
    title: "Transfer",
    icon: "business_bank",
    class: "",
    sub: null,
  },
  {
    path: "/enterprise-payment",
    title: "Enterprise Payment",
    icon: "business_money-coins",
    class: "",
    sub: null,
  },
  {
    path: "/withdraw",
    title: "Withdraw",
    icon: "business_chart-pie-36",
    class: "",
    sub: null,
  },
  {
    path: "/transaction",
    title: "Transaction",
    icon: "business_chart-bar-32",
    class: "",
    sub: null,
  },
  // {
  //   path: "/coupon",
  //   title: "Coupon",
  //   icon: "shopping_credit-card",
  //   class: "",
  //   sub: null,
  // },
  {
    path: "/lien",
    title: "Lien",
    icon: "transportation_bus-front-12",
    class: "",
    sub: null,
  },
  // {
  //   path: "/royalty",
  //   title: "Royalty",
  //   icon: "design-2_ruler-pencil",
  //   class: "",
  //   sub: null,
  // },
  // {
  //   path: "/bank-register",
  //   title: "Bank-Register",
  //   icon: "files_single-copy-04",
  //   class: "",
  //   sub: null,
  // },
  {
    path: "/setting",
    title: "Setting",
    icon: "ui-1_settings-gear-63",
    class: "",
    sub: null,
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }

  logout() {
    this.authService.userLogOut();
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
