import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Bank, ApiResponse } from "../../models";
import { ApiService, EnvService } from "../../services";

@Injectable()
export class Banks {
  banks: Bank[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=items,transaction, deposit`;
    this.recordRetrieve(queryString).then((res) => {
      this.banks = res.payload;
      console.log(res);
    });
  }

  query(params?: any) {
    if (!params) {
      return this.banks;
    }
    return this.banks.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: Bank) {
    this.banks.push(record);
  }

  delete(record: Bank) {
    this.banks.splice(this.banks.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ""): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/banks${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Bank): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/banks`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log("recordCreate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=items,deposit,transaction`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordUpdate(record: Bank, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/banks/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordUpdate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=items,deposit,transaction`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordPatch(record: Bank): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/banks/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordDelete(record: Bank): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/banks/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }
}
