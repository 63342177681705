import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PmlAgentService } from '../../services/pml-agent.service';
import { AuthService } from '../../services';
import {nextDate, setLocalStorage} from '../../helpers'
import { Staffs } from '../../providers';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formLoginHeight: string;
  loginForm: FormGroup;
  url: string = "https://5fa984da-8858-4004-af5d-5f3ac58c6f20.mock.pstmn.io/api/pml/pml-agents";
  userData: any;
  canRecordSales;


  // constructor(private router: Router,
  //   private agentService: PmlAgentService) { }
  constructor(private router: Router,
    private staffs: Staffs,
    private authSerive: AuthService) { }

  ngOnInit() {
    this.formLoginHeight = `calc(50vh - ${+document.getElementById('login-row').clientHeight / 2}px)`;
    this.initialSelectFields();
  }

  initialSelectFields() {
    this.loginForm = new FormGroup({
      // 'phone': new FormControl(null, [this.phoneValidation]),
      'email': new FormControl(null, [this.emailValidation]),
      'password': new FormControl(null, [Validators.required])
    });
  }

  phoneValidation = (control: FormControl): {
    [s: string]: boolean
    } => {
        if (/^[0][0-9]/.test(control.value) === false) {
            return {
                'phoneValidation': true
            };
        }
        return null;
    }

  emailValidation = (control: FormControl): {
    [s: string]: boolean
    } => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(control.value) === false) {
            return {
                'emailValidation': true
            };
        }
        return null;
    }

  goToDashboard(data: any){
    if(data.payload !== null || ''){
      this.router.navigate(['/dashboard']);
      window.localStorage.setItem('canRecord', 'true');
    }else{
      this.router.navigate(['/forgot-password'])
    }
  }
  async onSubmit() {
      if (this.loginForm.valid) {
        const currentElm = $('button.hovering.ld-over');
        currentElm.addClass('running');
        const payload = this.loginForm.value;
        await this.authSerive.postLogin(payload, currentElm);
        await this.staffs.getUserInformation().then(res => {
          console.log("USER ==> ", res)
          setLocalStorage('user', res.payload, null)
        })
      } else {
        this.loginForm.controls.email.markAsTouched();
        this.loginForm.controls.password.markAsTouched();
      }
  }
  monitorTimeForSales(){
    setInterval(()=>{
      let now = new Date();
      let tomorrow = nextDate();
      if(now !== tomorrow){
        return this.canRecordSales = true;
      }else{
        return this.canRecordSales = false;
      }
    }, 1000)
    
  }

  setLocalStorage(name: string, data: any){
    if(localStorage.getItem(name) === null || undefined){
      window.localStorage.setItem(name, JSON.stringify(data));
    }else{
      let previous = JSON.parse(window.localStorage.getItem(name));
      previous.push(data);
      window.localStorage.setItem(name, previous);
    }
  }
  
}
