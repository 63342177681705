// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  CURRENT_URL: "https://api.pmt.com.ng/api",
  //  CURRENT_URL: 'http://172.16.17.40/api'
};
