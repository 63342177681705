import { Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  colors: Array<any> = [
    {backgroundColor: 'blue',
    color: 'white'}
  ]

}
