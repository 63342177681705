import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EnterprisePayment, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class EnterprisePayments {

  enterprisePayments: EnterprisePayment[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    // let queryString = `?sort=-createdAt`;
    // queryString += `&populate=pmlTerminal,voucher,accountHeading`;
    // this.recordRetrieve(queryString).then(res => { this.enterprisePayments = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.enterprisePayments;
    }
    return this.enterprisePayments.filter((bankAccount) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = bankAccount[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return bankAccount;
          } else if (field === params[key]) {
            return bankAccount;
          }
        }
      }
      return null;
    });
  }

  add(record: EnterprisePayment) {
    this.enterprisePayments.push(record);
  }

  delete(record: EnterprisePayment) {
    this.enterprisePayments.splice(this.enterprisePayments.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/enterprise-payments${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }


  async recordCreate(record: EnterprisePayment): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/enterprise-payments`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: EnterprisePayment, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/ewallet/enterprise-payments/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminal,voucher,accountHeading`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: EnterprisePayment): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/enterprise-payments/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: EnterprisePayment): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/enterprise-payments/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
